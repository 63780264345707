(function(wnd){
    function CookieHandler() {
        this.init();
    }

    CookieHandler.prototype = {
        groups: {},
        options: {
            reloadOnSave: true,
        },
        init:function() {},
        handleCookieSave: function(response) {
            this.groups = response;
            if (response.deletableCookies) {
                this.deleteCookies(response.deletableCookies);
            }

            if (this.getOption('reloadOnSave')) {
                document.location.reload();
            } else {
                
            }
        },
        deleteCookies: function(cookies) {
            if (cookies && Array.isArray(cookies) && cookies.length > 0) {
                var documentCookies = document.cookie;
                for (var i = 0; i < cookies.length; i++) {
                    var name = cookies[i];
                    if (name && name !== 'ci_session' && documentCookies.indexOf(name) >= 0) {
                        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;domain=";
                        /*below line is to delete the google analytics cookies. they are set with the domain*/
                        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;domain=" + location.hostname.replace(/^info\./i, "");
                    }
                }
            }
        },
        getOption: function(key) {
            return typeof this.options[key] !== 'undefined' ? this.options[key] : null;
        }
    };
    wnd.CookieHandler = CookieHandler;
})(window);
